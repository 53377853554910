import { Component, Vue, Prop } from 'vue-property-decorator';
import licitacoes from '@/modules/licitacoes/services/licitacoes';
import utils from '@/utils';
import ShowPath from '@/components/showPath/showPath.vue';
import {
  Icons
} from '@/components/base';
  
@Component({
  components: {
    Icons,
    ShowPath
  }
})
export default class LicitacaoItem extends Vue {
  @Prop({required: true}) id!: string;

  isLoading = false;
  item: any = {};
  anexos = []

  ajusteDate = utils.date.strDatetimeUSAToBR
  ajusteHour = utils.date.ajustHour
  getSituacao = licitacoes.getSituacaoTitle
  getModalidade = licitacoes.getModalidadeTitle

  mounted() {
    this.getById(this.getRouteId());
  }

  getRouteId(){
    return this.id;
  }

  async getById(id: any){
    this.isLoading = true;
    try {
      const response = await licitacoes.sendGetLicitacaoById(id);
      this.item = response.data;
      // this.categoriaTitle = documentos.getCategoriaTitle(this.item.fkdocumentotipo);
      this.getAnexos(id);
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  }

  getAnexos(id: any){
    licitacoes.sendGetDocAnexosById(id)
      .then( (response) => {
        this.anexos = response.data.list;
      });
  }

  getUrlAnexo(file: any){
    let arr = file.arquivo;
    arr = arr.split('/')
    if( arr[2] == 'apitransparencia2.laranjadaterra.es.gov.br' ){
      let local = file.arquivo;
      local = local.replace('./', '/');
      return 'https://apitransparencia2.laranjadaterra.es.gov.br'+local;
    }
    if( arr[1] == 'uploads' &&  arr[2] == 'licitacao' ){
      return 'https://antigo.laranjadaterra.es.gov.br'+file.arquivo
    }
    if( arr[1] == 'novosUploads' &&  arr[2] == 'licitacoes' ){
      return 'https://antigo.laranjadaterra.es.gov.br'+file.arquivo
    }
    const text = file.arquivo.replace('http:/', '');
    return 'https://antigo.laranjadaterra.es.gov.br'+text;
  }

}
  